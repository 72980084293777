import { Button, Group, Modal, Radio, Space, Stack } from '@mantine/core';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { CollectionSite } from '@/types';

interface SelectCollectionSiteModalProps {
  opened: boolean;
  onClose: () => void;
  collectionSites: CollectionSite[];
  selectedSiteId: number | null;
  setSelectedSiteId: (siteId: number | null) => void;
  onSave: (e: React.SyntheticEvent) => void;
}

function SelectCollectionSiteModal({
  opened,
  onClose,
  collectionSites,
  selectedSiteId,
  setSelectedSiteId,
  onSave,
}: SelectCollectionSiteModalProps) {
  const { t } = useTranslation();

  return (
    <Modal
      opened={opened}
      onClose={onClose}
      title={t('Blood Draw Site')}
      centered
      size="md"
      styles={{
        title: {
          fontWeight: 700,
        },
      }}
    >
      <form onSubmit={onSave}>
        <Stack gap="lg">
          {collectionSites.map(site => (
            <Radio
              key={site.id}
              value={site.id}
              label={`${site.name} - ${site.full_address}`}
              checked={selectedSiteId?.toString() === site.id.toString()}
              onChange={event =>
                setSelectedSiteId(parseInt(event.target.value))
              }
            />
          ))}
        </Stack>
        <Space h="lg" />
        <Group p="right" mt="md" justify="flex-end">
          <Button variant="outline" onClick={onClose}>
            {t('Cancel')}
          </Button>
          <Button onClick={onClose} type="submit">
            {t('common.action.save')}
          </Button>
        </Group>
      </form>
    </Modal>
  );
}

export default SelectCollectionSiteModal;
