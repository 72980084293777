import { useForm, usePage } from '@inertiajs/react';
import { Button, Card, Group, MultiSelect } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import { Field } from '@/components';

interface Condition {
  name: string;
  disease_identifier: string;
  group_identifier: string;
  response: boolean;
}

interface HealthProfile {
  health_conditions: Condition[];
}

interface ResponseOptions {
  health_conditions: Condition[];
}

interface PageProps {
  client: {
    id: number;
  };
  health_profile: HealthProfile;
  response_options: ResponseOptions;
}

export function HealthProfileForm() {
  const { t } = useTranslation();

  const {
    client,
    health_profile: healthProfile,
    response_options: responseOptions,
  } = usePage<PageProps>().props;

  const { data, setData, patch, errors } = useForm(healthProfile);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch(`/provider/clients/${client.id}/health_profile`);
  };

  return (
    <form onSubmit={save}>
      <Card>
        <Card.Section>
          <Field label={t('health_profile.column.health_conditions')}>
            <MultiSelect
              error={errors.health_conditions}
              onChange={value => {
                let finalConditions = responseOptions.health_conditions;

                finalConditions = finalConditions.map(condition => ({
                  ...condition,
                  response: value.includes(condition.name),
                }));
                setData('health_conditions', finalConditions);
              }}
              value={data.health_conditions
                .filter(condition => condition.response)
                .map(condition => condition.name)}
              data={Object.values(responseOptions.health_conditions).map(
                option => option.name,
              )}
            />
          </Field>
        </Card.Section>
        <Card.Section>
          <Group justify="end" p="md">
            <Button type="submit">{t('common.action.save')}</Button>
          </Group>
        </Card.Section>
      </Card>
    </form>
  );
}
