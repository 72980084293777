import { useTranslation } from 'react-i18next';

import { Page } from '@/components';
import type { Vendor } from '@/types';

import { Form } from './_Form';

interface Props {
  vendor: Vendor;
}

function New({ vendor }: Props) {
  const { t } = useTranslation();

  return (
    <Page title={`${t('common.action.edit')} ${t('vendor.singular')}`}>
      <Form vendor={vendor} />
    </Page>
  );
}

export default New;
