import { usePage } from '@inertiajs/react';
import { Grid } from '@mantine/core';

import { Card } from './_Card';
import { Field } from './_Field';

interface Condition {
  name: string;
  disease_identifier: string;
  group_identifier: string;
  response: boolean;
}

interface IHealthProfile {
  health_conditions: Condition[];
}

interface PageProps {
  client: {
    id: number;
  };
  health_profile: IHealthProfile;
}

export function HealthProfile() {
  const { client, health_profile: healthProfile } = usePage<PageProps>().props;

  return (
    <Card
      title="Health Profile"
      editLink={`/provider/clients/${client.id}/health_profile`}
      padding={true}
      component={
        <Grid gutter="sm" p="md">
          <Field
            label="Diagnosed health conditions"
            value={healthProfile.health_conditions
              ?.filter((condition: Condition) => condition.response)
              ?.map((condition: Condition) => condition.name)
              ?.join(', ')}
          />
        </Grid>
      }
    />
  );
}
