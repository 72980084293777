import { useForm } from '@inertiajs/react';
import { Button, Card, MultiSelect, Stack } from '@mantine/core';
import type { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';

import type { Questionnaire, QuestionnaireOptions } from '@/types';

interface Props {
  action: string;
  healthProfile: Questionnaire;
  responseOptions: QuestionnaireOptions;
}

export function HealthProfileForm({
  action,
  healthProfile,
  responseOptions,
}: Props) {
  const { t } = useTranslation();

  const { data, setData, patch, errors } = useForm(healthProfile);

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch(action);
  };

  const handleHealthConditionsChange = (value: string[]) => {
    const updatedHealthConditions = responseOptions.health_conditions.map(
      healthCondition => ({
        ...healthCondition,
        response: value.includes(healthCondition.disease_identifier),
      }),
    );
    setData('health_conditions', updatedHealthConditions);
  };

  const hasSelectedConditions = data.health_conditions.some(
    condition => condition.response,
  );

  return (
    <form onSubmit={save}>
      <Card padding="xl">
        <Card.Section withBorder p="xl">
          <Stack gap="lg">
            <MultiSelect
              label={t('health_profile.column.health_conditions')}
              onChange={handleHealthConditionsChange}
              value={data.health_conditions
                .filter(condition => condition.response)
                .map(condition => condition.disease_identifier)}
              data={responseOptions.health_conditions.map(option => ({
                label: option.name,
                value: option.disease_identifier,
              }))}
              placeholder={
                hasSelectedConditions
                  ? undefined
                  : t('common.placeholder.select_an_option')
              }
              error={errors.health_conditions}
            />
            <Button type="submit">{t('common.action.submit')}</Button>
          </Stack>
        </Card.Section>
      </Card>
    </form>
  );
}
