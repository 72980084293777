import { Link, useForm, usePage } from '@inertiajs/react';
import { Box, Button, Group } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { notifications } from '@mantine/notifications';
import dayjs from 'dayjs';
import type { SyntheticEvent } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsGraphUpArrow } from 'react-icons/bs';
import { FaPen, FaRegFileAlt } from 'react-icons/fa';

import { DataGrid } from '@/components';
import { usePermissions, useTrackProviderEvent } from '@/hooks';
import type { CollectionSite } from '@/types';

import { Card } from './_Card';
import SelectCollectionSiteModal from './_SelectCollectionSiteModal';
import classes from './_Tests.module.css';

interface PageProps {
  client: {
    id: number;
    assays: {
      collection_site: CollectionSite;
      id: number;
      collected_on: string;
      report?: {
        id: number;
        state: string;
      };
      active_kit: {
        barcode_id: string;
      };
    }[];
  };
  links: {
    report_summaries: Record<number, string>;
  };
  collection_sites: CollectionSite[];
}

export function Tests() {
  const { hasPrivilege } = usePermissions();
  const { t } = useTranslation();

  const { client, links, collection_sites } = usePage<PageProps>().props;
  const [opened, { open, close }] = useDisclosure(false);
  const trackEvent = useTrackProviderEvent();
  const [selectedAssayId, setSelectedAssayId] = useState<number | null>(null);

  const { data, setData, patch } = useForm<{
    collection_site_id: number | null;
  }>({
    collection_site_id: null,
  });

  const save = (e: SyntheticEvent) => {
    e.preventDefault();
    patch(`/provider/clients/${client.id}/assays/${selectedAssayId}`, {
      onSuccess: () => {
        notifications.show({
          title: 'Success',
          message: 'Blood draw site updated successfully.',
          color: 'green',
        });
      },
      onError: () => {
        notifications.show({
          title: 'Error',
          message: 'Failed to update blood draw site. Please try again.',
          color: 'red',
        });
      },
    });
  };

  return (
    <>
      <Card
        title={`Tests (${client.assays.length})`}
        buttonLabel="New Test"
        buttonLink={
          hasPrivilege('change_assays')
            ? `/provider/clients/${client.id}/assays/new`
            : null
        }
        padding={false}
        component={
          <DataGrid
            data={client.assays}
            columns={[
              {
                header: 'Barcode',
                cell: ({ row }) =>
                  row.original.active_kit != null
                    ? row.original.active_kit.barcode_id
                    : '---',
              },
              {
                header: 'Collected On',
                cell: ({ row }) =>
                  row.original.collected_on != null
                    ? dayjs(row.original.collected_on).format('YYYY-MM-DD')
                    : '---',
              },
              {
                header: 'Blood Draw Site',
                cell: ({ row }) =>
                  row.original.collection_site ? (
                    <Group justify="flex-start">
                      {row.original.collection_site.name}{' '}
                      <FaPen
                        size={16}
                        className={classes['icon-pen']}
                        onClick={() => {
                          setSelectedAssayId(row.original.id);
                          setData(
                            'collection_site_id',
                            row.original.collection_site.id,
                          );
                          open();
                        }}
                      />
                    </Group>
                  ) : (
                    '---'
                  ),
              },
              {
                header: 'Requisitions',
                cell: ({ row }) => {
                  return row.original.collection_site ? (
                    <a
                      href={`/provider/assays/${row.original.id}/requisition_form`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Box className={classes['iconBox']}>
                        <FaRegFileAlt size={20} color="white" />
                      </Box>
                    </a>
                  ) : (
                    '---'
                  );
                },
              },
              {
                header: 'Test Status',
                cell: ({ row }) => {
                  const reportState = row.original.report?.state;
                  return reportState === 'released' ? (
                    'Report Released'
                  ) : reportState === 'provider_review' &&
                    hasPrivilege('change_reports') ? (
                    <Link
                      href={`/provider/reports/${row.original.report?.id ?? ''}/release`}
                      method="post"
                    >
                      <Button
                        onClick={e => {
                          if (!confirm(t('provider.confirm.release'))) {
                            e.preventDefault();
                          } else {
                            trackEvent('release_report_provider');
                          }
                        }}
                      >
                        Release Report
                      </Button>
                    </Link>
                  ) : (
                    '---'
                  );
                },
              },
              {
                header: 'Reports',
                id: 'download_report',
                cell: ({ row }) =>
                  row.original.report?.id != null &&
                  links.report_summaries[row.original.report.id] != null ? (
                    <a
                      href={links.report_summaries[row.original.report.id]}
                      onClick={() => {
                        trackEvent('pdf_download_provider');
                      }}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Box className={classes['iconBox']}>
                        <BsGraphUpArrow size={20} color="white" />
                      </Box>
                    </a>
                  ) : (
                    '---'
                  ),
              },
            ]}
          />
        }
      />
      <SelectCollectionSiteModal
        opened={opened}
        onClose={close}
        collectionSites={collection_sites}
        selectedSiteId={data.collection_site_id}
        setSelectedSiteId={id => setData('collection_site_id', id)}
        onSave={save}
      />
    </>
  );
}
