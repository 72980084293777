import { Grid, Space, Stack } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import type { ReactElement, ReactNode } from 'react';

import { usePermissions } from '@/hooks';
import { Provider } from '@/layouts';
import type { Client, CollectionSite } from '@/types';

import { HealthProfile } from './_HealthProfile';
import { PersonalInfo } from './_PersonalInfo';
import { ReportStatus } from './_ReportStatus';
import { SubHeader } from './_SubHeader';
import { Tests } from './_Tests';

interface Props {
  client: Client;
  collection_sites: CollectionSite[];
}

function Show({ client }: Props) {
  const { hasPrivilege } = usePermissions();
  return (
    <Stack>
      <ReportStatus />
      <Grid gutter="xl">
        <Grid.Col span={{ base: 12, md: 5 }}>
          <PersonalInfo />
          <Space h="xl" />
          {client.share_with_provider &&
            hasPrivilege('change_health_profiles') && <HealthProfile />}
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 7 }}>
          <>
            <Notifications position="top-right" zIndex={1000} />
            <Tests />
          </>
        </Grid.Col>
      </Grid>
    </Stack>
  );
}

Show.layout = (page: ReactNode & { props: Props }): ReactElement => (
  <Provider subHeader={<SubHeader client={page.props.client} />} page={page} />
);

export default Show;
